import dayjs from "dayjs";
import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo, GatsbySeoProps } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";
import { ShareButtons } from "@/components/ShareButtons";
import { TagListItem } from "@/components/TagListItem";

const contentOnError = `
    <div>
        <p>Something went wrong...</p>
        <p>申し訳ありません、トラブルが発生したようです</p>
    </div>
`;

const PostPage: React.FC<PageProps<Queries.PostQuery>> = ({ data }) => {
  const post = data.contentfulPost;
  const content = post?.content?.childMarkdownRemark?.html ?? contentOnError;
  const pageUrl = data.site?.siteMetadata?.siteUrl + "/post/" + post?.slug;
  const author = data.contentfulPost?.author;

  const formatedCreatedAt = dayjs(post?.createdAt).format("YYYY-MM-DD");
  // const formatedUpdatedAt = dayjs(post?.updatedAt).format("MMM D");

  const seoProps: GatsbySeoProps = {
    title: post?.title ?? "Post",
    description: post?.content?.childMarkdownRemark?.excerpt ?? undefined,
    openGraph: {
      title: post?.title ?? "Post",
      description: post?.content?.childMarkdownRemark?.excerpt ?? undefined,
      url: pageUrl,
      images: [
        {
          url: post?.ogImage?.url ?? "",
        },
      ],
      article: {
        publishedTime: post?.createdAt ?? "",
        // authors:[]
        tags: post?.metadata?.tags?.map((tag) => tag?.name ?? "ビジネス"),
        section: "ビジネス",
      },
    },
  };

  return (
    <Layout>
      <GatsbySeo {...seoProps} />
      <div className="mx-auto flex max-w-3xl flex-col">
        <div className="prose max-w-full pt-12 pb-4">
          <time>{formatedCreatedAt}</time>
          <h1 className="mt-4 mb-8 md:text-5xl">{post?.title}</h1>
        </div>

        <div className="flex items-center gap-4">
          <Link
            to={"/author/" + author?.slug}
            className="group flex items-center gap-4"
          >
            {author?.icon?.gatsbyImageData && (
              <GatsbyImage
                image={author?.icon?.gatsbyImageData}
                alt={author.name ?? ""}
                imgStyle={{ borderRadius: "100%" }}
                className="h-12 w-12 rounded-full transition-opacity group-hover:opacity-70"
              />
            )}

            <span className="font-semibold text-gray-500 transition-all group-hover:text-gray-400">
              {author?.name}
            </span>
          </Link>
          <span className="font-medium text-gray-500">
            {post?.content?.childMarkdownRemark?.timeToRead} min read
          </span>
        </div>
        <ul className="mt-4 flex list-none flex-wrap gap-4 text-gray-500">
          {post?.metadata?.tags?.map((tag) => tag && <TagListItem {...tag} />)}
        </ul>
        <div
          className="prose mb-20 max-w-full prose-h2:mt-24  prose-h2:mb-10 prose-h2:py-2  prose-h2:text-4xl prose-h2:font-extrabold prose-a:text-yellow-600"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="mt-10 flex flex-col items-center rounded-lg border-4 px-8 pt-8 text-center">
          <h3 className="highlight pb-4 text-lg font-bold">Please Share</h3>
          <p>
            この投稿が参考になったらぜひシェアしてください。
            あなたの感想がBoctozの力になります😊
          </p>
          <ShareButtons
            url={pageUrl}
            title={post?.title + " | Boctoz " ?? ""}
          />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Post($slug: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      author {
        slug
        name
        icon {
          gatsbyImageData(formats: WEBP, placeholder: BLURRED, width: 160)
        }
      }
      createdAt
      # updatedAt
      content {
        childMarkdownRemark {
          html
          timeToRead
          excerpt
        }
      }
      title
      slug
      ogImage {
        url
      }
      metadata {
        tags {
          name
          contentful_id
        }
      }
    }
  }
`;

export default PostPage;
