import * as React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  HatenaShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  HatenaIcon,
  LineIcon,
  PocketShareButton,
  PocketIcon,
} from "react-share";

interface ShareButtonsProps {
  url: string;
  title: string;
}

const track = (label: string) => {
  typeof window !== "undefined" &&
    window.gtag("event", "click", {
      event_category: "Share",
      event_label: label,
    });
};

export const ShareButtons: React.FC<ShareButtonsProps> = ({ url, title }) => {
  return (
    <div className="flex w-4/5 flex-wrap justify-center gap-4 py-8">
      <TwitterShareButton
        url={url}
        title={title}
        onClick={() => track("Twitter")}
      >
        <TwitterIcon size={48} round />
      </TwitterShareButton>

      <FacebookShareButton
        url={url}
        title={title}
        onClick={() => track("Facebook")}
      >
        <FacebookIcon size={48} round />
      </FacebookShareButton>

      <HatenaShareButton
        url={url}
        title={title}
        onClick={() => track("Hatena")}
      >
        <HatenaIcon size={48} round />
      </HatenaShareButton>

      <LineShareButton url={url} title={title} onClick={() => track("Line")}>
        <LineIcon size={48} round />
      </LineShareButton>

      <PocketShareButton
        url={url}
        title={title}
        onClick={() => track("Pocket")}
      >
        <PocketIcon size={48} round />
      </PocketShareButton>
    </div>
  );
};
